<template>
  <Template v-bind="{
    ...params,
    isLoading
  }" @onSubmit="onSave" @onCopy="onCopy" />
</template>
<script>
import Template from '../components/templates/InvitePeople/InvitePeople';
import { c } from '@/components/constants.js'
import { Settings } from "../../settings";
import useClipboard from 'vue-clipboard3'

export default {
  components: {
    Template,
  },
  methods: {
    async onSave() {
      const items = this.params.inputs.emails.value?.split(',');
      if (items?.length > 0) {
        const contacts = [];

        items.map(e => contacts.push({
          email: e.trim(),
        }));
        await this.actions.user.inviteContact({
          contacts: contacts,
          userId: this.state.currentUser?.id
        });
        this.actions.alert.showSuccess({ message: 'Invited contacts successfully' });

      }
      this.actions.setCurrentCompany(this.state.currentUser?.company);
      this.$router.push({ name: 'Ecosystem Detail', params: { companyId: this.state.currentUser?.company?.id } })
    },
    async onCopy() {
      const { toClipboard } = useClipboard()
      try {
        await toClipboard(this.params.inputs.url.value)
        this.actions.alert.showSuccess({ message: 'Copied to your clipboard' })
      } catch (e) {
        console.error(e)
      }
    }
  },
  data() {
    return {
      params: c.invite_people,
      isLoading: false,
    }
  },
  mounted() {
    this.params.inputs.url.value = `https://${Settings.mainDomain}/#/register-user?c=${this.state.currentUser?.company?.id}`;
  }
}
</script>
